
export const ViewMode = {
    Checkin: "checkin",
    Incident: "incident",
    Value: "value",
    Probe: "probe",
}


const MainDataState = {

    viewMode: ViewMode.Checkin,

}

export default MainDataState

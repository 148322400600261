import React, {useContext} from "react"
import {Box, Button} from "@mui/material";
import {MainDataContext} from "../index";
import {ViewMode} from "../../../data/state";

const styles = {
    root: {
        height: "40px",
        margin: "8px",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
    }
}

const HeaderView = (props) => {

    const { state, setViewMode } = useContext(MainDataContext)

    return (
        <Box style={styles.root}>
            <Button variant={state.viewMode === ViewMode.Checkin ? "contained": "outlined"} onClick={() => setViewMode(ViewMode.Checkin)}>チェックイン一覧</Button>
            <Button variant={state.viewMode === ViewMode.Incident ? "contained": "outlined"} onClick={() => setViewMode(ViewMode.Incident)}>インシデント一覧</Button>
            <Button variant={state.viewMode === ViewMode.Value ? "contained": "outlined"} onClick={() => setViewMode(ViewMode.Value)}>計測一覧</Button>
            <Button variant={state.viewMode === ViewMode.Probe ? "contained": "outlined"} onClick={() => setViewMode(ViewMode.Probe)}>プローブ</Button>
        </Box>
    )
}

export default HeaderView

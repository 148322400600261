import axios from "axios";


const UseApiManager = () => {


    const get = (path, params) => {
        return new Promise((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_API_ENDPOINT}/${path}`,
                method: "GET",
                params: params
            }).then(res => {
                resolve(res.data.rows)
            }).catch(reject)
        })
    }

    return {
        get
    }

}

export default UseApiManager

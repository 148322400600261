import React, {useEffect, useState} from "react"
import {Box, Typography} from "@mui/material";
import UseApiManager from "../../../manager/api";
import {AgGridReact} from "ag-grid-react";
import dayjs from "dayjs";

const lengthStr = (value) => {
    if (value > 110000) {
        return parseInt(value / 1000) + "km"
    } else if (value > 10000) {
        return (value / 1000).toFixed(1) + "km"
    }
    return parseInt(value) + "m"
}

const columnDefs = [
    {field: "day", headerName: "日付", valueFormatter: (param) => dayjs(param.value).format("YYYY-MM-D")},
    {field: "user_id", headerName: "ユーザID"},
    {field: "lon", headerName: "経度"},
    {field: "lat", headerName: "緯度"},
    {field: "zone_name", headerName: "ゾーン"},
    {field: "nearest_facility_code", headerName: "直近の施設コード"},
    {field: "nearest_facility_name", headerName: "直近の施設名"},
    {field: "nearest_facility_distance", headerName: "直近の施設距離", valueFormatter: (params) => lengthStr(params.value) },
    {field: "measured_at", headerName: "受信日時", valueFormatter: (params) => dayjs(params.value).format("YYYY-MM-DD HH:mm:ss") },
]

const ProbeListView = () => {

    const [rowData, setRowData] = useState()
    const { get } = UseApiManager()

    useEffect(() => {
        get("test/probes")
            .then(setRowData)
    }, [])

    useEffect(() => {
        console.log("[APIResponse]", rowData)
    }, [rowData])

    return (
        <Box style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
            <Box style={{flexGrow: 1}}>
                <AgGridReact
                    style={{flexGrow: 1}}
                    className={'ag-theme-balham'}
                    rowData={rowData}
                    defaultColDef={{
                        floatingFilter: true,
                        filter: true,
                    }}
                    columnDefs={columnDefs}
                /></Box>
        </Box>
    )
}

export default ProbeListView

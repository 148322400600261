import React, {useEffect, useState} from "react"
import {Box, IconButton, Typography} from "@mui/material";
import UseApiManager from "../../../manager/api";
import {AgGridReact} from "ag-grid-react";
import {RecyclingRounded as RecyclingRoundedIcon} from "@mui/icons-material";
import dayjs from "dayjs";

const columnDefs = [
    {field: "day", headerName: "日付", valueFormatter: (params) => dayjs(params.value).format("YYYY-MM-DD")},
    {field: "user_id", headerName: "ユーザID"},
    {field: "zone_name", headerName: "ゾーン"},
    {field: "facility_code", headerName: "施設コード"},
    {field: "facility_name", headerName: "施設名"},
    {field: "facility_type", headerName: "施設タイプ"},
    {field: "checkin_at", headerName: "チェックイン時間", valueFormatter: (params) => dayjs(params.value).format("HH:mm") },
    {field: "checkout_at", headerName: "チェックアウト時間", valueFormatter: (params) => params.value ? dayjs(params.value).format("HH:mm") : "未" },
]

const CheckinListView = () => {

    const [rowData, setRowData] = useState()
    const { get } = UseApiManager()

    useEffect(() => {
        get("test/checkins")
            .then(setRowData)
    }, [])

    useEffect(() => {
        console.log("[APIResponse]", rowData)
    }, [rowData])

    return (
        <Box style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
            <Box style={{flexGrow: 1}}>
            <AgGridReact
                style={{flexGrow: 1}}
                className={'ag-theme-balham'}
                rowData={rowData}
                defaultColDef={{
                    floatingFilter: true,
                    filter: true,
                }}
                columnDefs={columnDefs}
            /></Box>
        </Box>
    )
}

export default CheckinListView

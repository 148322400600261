import React from "react"
import {Box, Typography} from "@mui/material";
import HeaderView from "./header";
import UseMainData from "../../data";
import {ViewMode} from "../../data/state";
import IncidentList from "../list/incident"
import CheckinList from "../list/checkin"
import ValueList from "../list/value"
import ProbeList from "../list/probe"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-balham.min.css"

export const MainDataContext = React.createContext()

const styles = {
    root: {
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100vw",
        height: "100vh",
    }
}

const MainView = () => {

    const mainData = UseMainData()

    const { state } = mainData

    return (
        <MainDataContext.Provider value={mainData}>
            <Box style={styles.root}>
                <HeaderView />
                <Box style={{flexGrow: 1}}>
                {state.viewMode === ViewMode.Incident && <IncidentList />}
                {state.viewMode === ViewMode.Checkin && <CheckinList />}
                {state.viewMode === ViewMode.Value && <ValueList />}
                {state.viewMode === ViewMode.Probe && <ProbeList />}
                </Box>
            </Box>
        </MainDataContext.Provider>
    )

}

export default MainView

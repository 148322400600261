import React, {useEffect, useState} from "react"
import {Box, Typography} from "@mui/material";
import UseApiManager from "../../../manager/api";
import {AgGridReact} from "ag-grid-react";
import dayjs from "dayjs";

const columnDefs = [
    {field: "day", headerName: "日付", valueFormatter: (param) => dayjs(param.value).format("YYYY-MM-DD")},
    {field: "user_id", headerName: "ユーザID"},
    {field: "facility_code", headerName: "施設コード"},
    {field: "facility_name", headerName: "施設名"},
    {field: "facility_type", headerName: "施設タイプ"},
    {field: "report_text", headerName: "状態"},
    {field: "repair_text", headerName: "処置"},
    {field: "lon", headerName: "経度"},
    {field: "lat", headerName: "緯度"},
    {field: "measured_at", headerName: "登録日時", valueFormatter: (params) => dayjs(params.value).format("YYYY-MM-DD HH:mm:ss")}
]

const IncidentListView = () => {

    const [rowData, setRowData] = useState()
    const { get } = UseApiManager()

    useEffect(() => {
        get("test/incidents")
            .then(setRowData)
    }, [])

    useEffect(() => {
        console.log("[APIResponse]", rowData)
    }, [rowData])

    return (
        <Box style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
            <Box style={{flexGrow: 1}}>
                <AgGridReact
                    style={{flexGrow: 1}}
                    className={'ag-theme-balham'}
                    rowData={rowData}
                    defaultColDef={{
                        floatingFilter: true,
                        filter: true,
                    }}
                    columnDefs={columnDefs}
                /></Box>
        </Box>
    )
}

export default IncidentListView

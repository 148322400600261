import logo from './logo.svg';
import './App.css';
import MainView from "./views/main";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Tokyo")

function App() {
  return (
      <MainView />
  )
}

export default App;

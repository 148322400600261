
import initialState from "./state"
import reducer, {ActionType} from "./reducer"
import {useReducer} from "react";

const UseMainData = () => {

    const [state, dispatch] = useReducer(reducer, {
        ...initialState
    })

    const setViewMode = (value) => {
        dispatch({type: ActionType.SetViewMode, value})
    }

    return {
        state,
        setViewMode
    }
}

export default UseMainData


export const ActionType = {
    SetViewMode: "SET_VIEW_MODE",
}

const MainDataReducer = (state, action) => {

    let { type, value } = action


    switch(type) {
        case ActionType.SetViewMode:
            return {...state, viewMode: value}
        default:
            break
    }

    return state
}

export default MainDataReducer
